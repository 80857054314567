<template>
  <div class="content">
    <div class="discount-detail">
      <div v-if="!discount.photos.length" class="discount-detail__img">
        <img src="@/assets/img/discount-slug.svg" alt="Discount slug">
      </div>
      <div v-else class="discount-detail__slider">
        <Swiper :options="swiper_options" >
          <SwiperSlide v-for="photo in discount.photos" :key="photo.id">
            <img :src="photo.file" alt="Discount photo">
          </SwiperSlide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </Swiper>
      </div>
      <div class="content__party content__party--start">
        <router-link
          :to="{ name: 'partners-profile', params: {id: this.$route.params.id}}"
          class="button button--light button--no-indent button--small"
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
          </svg>
        </router-link>
        <h1 class="title title--big title--color">{{ discount.title }}</h1>
      </div>
      <div class="discount-detail__info">
        <div v-if="discount.size" class="discount-detail__block">
          <div class="discount-detail__prop">Размер скидки:</div>
          <div class="discount-detail__value">{{ discount.size }}%</div>
        </div>
        <div class="discount-detail__block">
          <div class="discount-detail__prop">Срок действия:</div>
          <div class="discount-detail__value">{{ discount.date_start }} <span v-if="discount.date_end">- {{ discount.date_end }}</span></div>
        </div>
      </div>
      <div class="discount-detail__desc">
        {{ discount.desc }}
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper.scss';

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      discount: {
        photos: [],
      },
      swiper_options: {
        slidesPerView: 1,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
    };
  },
  mounted() {
    this.fetchDiscount();
  },
  methods: {
    fetchDiscount() {
      this.$store.dispatch('overall/GET_DISCOUNT', {id: this.$route.params.id, discount: this.$route.params.discount})
        .then(response => {
          this.discount = response.data;
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Внимание!',
            text: 'Ошибка получения данных',
          });
        });
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.discount-detail__slider
  position: relative
  margin-bottom: 50px

  .swiper-container
    height: 500px

    +max-w($mobile_xl)
      height: 300px

    +max-w($mobile_sm)
      height: 200px

  .swiper-slide
    overflow: hidden
    border-radius: 10px
    cursor: pointer

    img
      width: 100%
      height: 100%
      object-fit: cover

  .swiper-button-prev,
  .swiper-button-next
    position: absolute
    top: 50%
    z-index: 10
    width: 26px
    height: 46px
    cursor: pointer
    transform: translateY(-50%)
    background-repeat: no-repeat
    background-size: cover

    +max-w($mobile_sm)
      width: 15px
      height: 25px

  .swiper-button-prev
    left: 30px
    background-image: url("../../../assets/img/slider-prev.svg")

    +max-w($mobile_sm)
      left: 10px

  .swiper-button-next
    right: 30px
    background-image: url("../../../assets/img/slider-next.svg")

    +max-w($mobile_sm)
      right: 10px

.discount-detail__img
  overflow: hidden
  margin-bottom: 50px
  border-radius: 10px

  img
    display: block
    margin: 0 auto
    border-radius: 10px

  +max-w($mobile_md)
    margin-bottom: 120px

.discount-detail__info
  display: flex
  margin-bottom: 30px

  +max-w($mobile_sm)
    flex-direction: column

.discount-detail__block
  margin-right: 40px
  font-size: 20px
  font-weight: 600

  +max-w($mobile_sm)
    margin-right: 0
    margin-bottom: 20px

  &:last-child
    margin-right: 0

    +max-w($mobile_sm)
      margin-bottom: 0

.discount-detail__prop
  margin-bottom: 5px

.discount-detail__value
  color: $color-theme

.discount-detail__desc
  font-size: 16px
</style>